.record-video-education-anchor {
  position: absolute;
  top: 36px;
  right: 30px;
}
.record-video-education {
  z-index: 1;
}

.record-video-education img {
  height: 180px;
  width: auto;
}
